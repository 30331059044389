import { useState } from 'react';
import {
  BackdropLoader,
  BreadCrumbs,
  Header,
  SeparatorComponent,
  useAuthStore,
} from '@exertis/template';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import StatisticForm from '../components/statisticConfig/StatisticForm';
import StatisticFormPreview from '../components/statisticConfig/StatisticFormPreview';
import StatisticResultList from '../components/statisticConfig/StatisticResultList';
import StatisticResultGraph from '../components/statisticConfig/StatisticResultGraph';
import { useGetApps, useGlobalRoleList } from '../hooks/access';
import {
  AddStatistic,
  EditStatistic,
  useGetStatisticById,
  usePreviewStatistic,
} from '../hooks/statistic';

const StatisticConfig = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();
  const { me } = useAuthStore();

  /**
   * Get params
   */
  const { idStatistic } = useParams();

  /**
   * Global states
   */
  const [codeSql, setCodeSql] = useState('');
  const [dbName, setDbName] = useState('');
  const [graphType, setGraphType] = useState('');

  /**
   * GlobalRole api calls
   */
  const { data: globalRole, isInitialLoading: isGlobalRoleLoading } =
    useGlobalRoleList();

  /**
   * Apps api calls
   */
  const { data: apps, isInitialLoading: isAppLoading } = useGetApps();

  /**
   * Statistic api calls
   */
  const { data: statisticByIdToEdit, isInitialLoading: loaderStatisticById } =
    useGetStatisticById(idStatistic);
  const mutateAddStatistic = AddStatistic();
  const mutateEditStatistic = EditStatistic();
  const {
    data: previewStat,
    isInitialLoading: isPreviewStatLoading,
    refetch,
  } = usePreviewStatistic(dbName, codeSql, graphType);

  /**
   * Init breadCrumb items
   */
  const routeItems = [
    {
      path: '/statistic-config',
      links: [
        {
          title: t('statisticConfig:header.breadCrumbs_first_child'),
          href: '/',
        },
        {
          title: t('statisticConfig:header.breadCrumbs_second_child'),
          href: '/statistic-config',
        },
      ],
    },
  ];

  /**
   * Return TSX content
   */
  return (
    <>
      <Header
        welcome={t('statisticConfig:header.welcome')}
        username={me?.firstName || ''}
        titlePage={t('statisticConfig:header.title')}
        breadCrumbs={BreadCrumbs(routeItems)}
      />
      <SeparatorComponent />
      {loaderStatisticById || isGlobalRoleLoading || isAppLoading ? (
        <BackdropLoader open={true} />
      ) : (
        <Grid container sx={{ mb: { xs: 10, md: 10, lg: 10 } }}>
          <Grid container item xl={6} md={6} sm={12}>
            <StatisticForm
              statisticByIdToEdit={statisticByIdToEdit}
              globalRole={globalRole?.data}
              apps={apps?.data}
              mutateAddStatistic={mutateAddStatistic}
              mutateEditStatistic={mutateEditStatistic}
              setCodeSql={setCodeSql}
              setDbName={setDbName}
              setGraphType={setGraphType}
            />
          </Grid>
          {codeSql && dbName && graphType && (
            <>
              <Grid container item xl={6} md={6} sm={12}>
                <StatisticFormPreview codeSql={codeSql} refetch={refetch} />
                {isPreviewStatLoading ? (
                  <BackdropLoader open={isPreviewStatLoading} />
                ) : (
                  <>
                    {previewStat?.length > 0 ? (
                      <Grid item xs={12}>
                        <StatisticResultGraph
                          previewStat={previewStat}
                          graphType={graphType}
                        />
                        <StatisticResultList previewStat={previewStat} />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default StatisticConfig;

import { RouteObject } from 'react-router-dom';
import { NotFound, AccessDenied, LayoutSidebar } from '@exertis/template';

import Dashboard from '../pages/Dashboard';
import Statistic from '../pages/Statistic';
import StatisticConfig from '../pages/StatisticConfig';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <LayoutSidebar />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/active',
        element: <Statistic isActive={true} />,
      },
      {
        path: '/inactive',
        element: <Statistic isActive={false} />,
      },
      {
        path: 'statistic-config',
        element: <StatisticConfig />,
      },
      {
        path: 'statistic-config/:idStatistic',
        element: <StatisticConfig />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'AccessDenied',
    element: <AccessDenied />,
  },
];

export default routes;

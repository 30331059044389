import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BackdropLoader,
  BreadCrumbs,
  DashboardStats,
  Header,
  SeparatorComponent,
  useAuthStore,
} from '@exertis/template';

import config from '../config';
import { idApp } from '../constants/appConfig';
import { useInitNavbarApps } from '../hooks/app';
import {
  useGetStatisticQueries,
  useGetStatisticSorted,
} from '../hooks/statistic';
import { useGetRandomAdvertisement } from '../hooks/advertisement';
import { Statistic } from '../types/StatisticTypes';

const Dashboard = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();
  const { me } = useAuthStore();
  useInitNavbarApps();

  /**
   * Global states
   */
  const [statIds, setStatIds] = useState<string[]>([]);

  /**
   * Statistic api calls
   */
  const { data: statsList, isInitialLoading: isStatsLoading } =
    useGetStatisticSorted(idApp);
  const result = useGetStatisticQueries(statIds);

  /**
   * Advertisement api calls
   */
  const { data: advertisement } = useGetRandomAdvertisement();

  /**
   * Init list of stats to fetch their data
   */
  useEffect(() => {
    if (statsList) {
      setStatIds(statsList?.data.map((item: Statistic) => item.id));
    }
  }, [statsList]);

  /**
   * Init breadCrumb items
   */
  const routeItems = [
    {
      path: '/',
      links: [
        {
          title: t('dashboard:header.breadCrumbs_first_child'),
          href: '/',
        },
      ],
    },
  ];

  /**
   * Return TSX content
   */
  return (
    <>
      <Header
        welcome={t('dashboard:header.welcome')}
        username={me?.firstName || ''}
        titlePage={t('dashboard:header.title')}
        breadCrumbs={BreadCrumbs(routeItems)}
      />
      <SeparatorComponent />
      {isStatsLoading ? (
        <BackdropLoader open={isStatsLoading} />
      ) : (
        statsList && (
          <DashboardStats
            stats={statsList?.data}
            statQueries={result}
            advertisement={
              advertisement
                ? {
                    ...advertisement,
                    pubBaseUrl: config.advertisementApiBaseUrl,
                  }
                : null
            }
          />
        )
      )}
    </>
  );
};

export default Dashboard;

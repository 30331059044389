import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import {
  AppData,
  FormHelperErrorText,
  GlobalRoleType,
  GraphTypes,
  ParagraphTypography,
  SecondaryButton,
  useAppForm,
} from '@exertis/template';
import { object, string, array } from 'yup';

import {
  Grid,
  CardContent,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  TextareaAutosize,
} from '@mui/material';

import { databases } from '../../constants/databases';
import { priorities } from '../../constants/statisticConfig';
import { Statistic } from '../../types/StatisticTypes';

type Props = {
  statisticByIdToEdit: Statistic | undefined;
  globalRole: GlobalRoleType[] | undefined;
  apps: AppData[] | undefined;
  mutateAddStatistic: any;
  mutateEditStatistic: any;
  setCodeSql(code: string): void;
  setDbName(dbName: string): void;
  setGraphType(graphType: string): void;
};

const StatisticForm = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init navigation
   */
  let navigate = useNavigate();

  /**
   * Init props
   */
  const {
    statisticByIdToEdit,
    globalRole,
    apps,
    mutateAddStatistic,
    mutateEditStatistic,
    setCodeSql,
    setDbName,
    setGraphType,
  } = props;

  /**
   * Init states
   */
  const [appsCheckbox, setAppsCheckbox] = useState<AppData[] | undefined>([]);
  const [idGlobalRolesCheckbox, setIdGlobalRolesCheckbox] = useState<
    GlobalRoleType[] | undefined
  >([]);

  /**
   * Schema validation form
   */
  const schema = object().shape({
    name: string()
      .required(t('statisticConfig:form.validation.required'))
      .max(60, t('statisticConfig:form.validation.maxName')),
    description: string()
      .required(t('statisticConfig:form.validation.required'))
      .max(500, t('statisticConfig:form.validation.maxDescription')),
    databaseName: string().required(
      t('statisticConfig:form.validation.required')
    ),
    sqlCode: string().required(t('statisticConfig:form.validation.required')),
    graph: string().required(t('statisticConfig:form.validation.required')),
    idGlobalRoles: array()
      .typeError(t('statisticConfig:form.validation.required'))
      .required(t('statisticConfig:form.validation.required'))
      .min(1, t('statisticConfig:form.validation.required')),
    statisticApps: array()
      .typeError(t('statisticConfig:form.validation.required'))
      .required(t('statisticConfig:form.validation.required'))
      .min(1, t('statisticConfig:form.validation.required')),
    priority: string().required(t('statisticConfig:form.validation.required')),
  });

  /**
   * App form of react hook form
   */
  const appForm = useAppForm(schema);
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = appForm;

  /**
   * Handle globalRole checkbox changes
   * @param event
   */
  const handleChangeRoleCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: GlobalRoleType[] = [];

    value.forEach((item: any) => {
      if (
        duplicateRemoved.findIndex((o: GlobalRoleType) => o.id === item.id) >= 0
      ) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter(
            (x: GlobalRoleType) => x.id === item.id
          );
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setIdGlobalRolesCheckbox(duplicateRemoved);
  };

  /**
   * Handle app checkbox changes
   * @param event
   */
  const handleChangeAppCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: AppData[] = [];

    value.forEach((item: any) => {
      if (duplicateRemoved.findIndex((o: AppData) => o.id === item.id) >= 0) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter(
            (x: AppData) => x.id === item.id
          );
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setAppsCheckbox(duplicateRemoved);
  };

  /**
   * Handle change language
   */
  const handleLabelTranslation = (label: string) => {
    switch (label) {
      case 'clients':
        return t('common:globalRoles.clients');
      case 'collaborateurs':
        return t('common:globalRoles.collaborateurs');
      case 'fournisseurs':
        return t('common:globalRoles.fournisseurs');
      default:
        return '';
    }
  };

  /**
   * Handle submit form
   * @param data
   */
  const onSubmit = (data: any) => {
    let dataToSave = {
      ...data,
      idGlobalRoles: data.idGlobalRoles.map((a: any) => a.id),
      statisticApps: data.statisticApps.map((a: any) => a.id),
    };

    if (statisticByIdToEdit)
      mutateEditStatistic.mutateAsync({
        id: statisticByIdToEdit.id,
        data: dataToSave,
      });
    else
      mutateAddStatistic.mutateAsync(dataToSave).then((result: any) => {
        navigate(`/statistic-config/${result.id}`);
      });
  };

  /**
   * Init globalRole and app lists on edit statistic
   */
  useEffect(() => {
    if (statisticByIdToEdit && apps && globalRole) {
      const globalRolesValue = globalRole?.filter((item: GlobalRoleType) =>
        statisticByIdToEdit?.idGlobalRoles.includes(item.id)
      );
      const appsValue = apps?.filter((item: AppData) =>
        statisticByIdToEdit?.apps.includes(item?.id!)
      );
      setIdGlobalRolesCheckbox(globalRolesValue);
      setAppsCheckbox(appsValue);
      setValue('idGlobalRoles', globalRolesValue);
      setValue('statisticApps', appsValue);
      setCodeSql(watch('sqlCode'));
      setDbName(watch('databaseName'));
      setGraphType(watch('graph'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticByIdToEdit, apps, globalRole]);

  /**
   * Return TSX content
   */
  return (
    <Grid container>
      <CardContent sx={{ px: { xs: 1, md: 5, lg: 15 } }}>
        <FormProvider {...appForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid rowSpacing={{ sm: 5, lg: 2 }} container>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.statName')}
                </ParagraphTypography>
                <TextField
                  {...register('name')}
                  defaultValue={statisticByIdToEdit?.name || ''}
                  error={getFieldState('name').error ? true : false}
                  fullWidth
                />
                {getFieldState('name').error && (
                  <FormHelperErrorText>
                    {getFieldState('name')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.statDescription')}
                </ParagraphTypography>
                <TextField
                  {...register('description')}
                  defaultValue={statisticByIdToEdit?.description || ''}
                  error={getFieldState('description').error ? true : false}
                  fullWidth
                />
                {getFieldState('description').error && (
                  <FormHelperErrorText>
                    {getFieldState('description')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.databaseName')}
                </ParagraphTypography>
                <FormControl
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '100%',
                      lg: '100%',
                    },
                  }}
                >
                  <Select
                    {...register('databaseName')}
                    displayEmpty={true}
                    defaultValue={statisticByIdToEdit?.databaseName || ''}
                    error={getFieldState('databaseName').error ? true : false}
                    fullWidth
                  >
                    {databases?.map((item, key: number) => {
                      return (
                        <MenuItem
                          value={item}
                          key={key}
                          onClick={() => {
                            setDbName(item);
                          }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {getFieldState('databaseName').error && (
                  <FormHelperErrorText>
                    {getFieldState('databaseName')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.sqlCode')}
                </ParagraphTypography>
                <TextareaAutosize
                  {...register('sqlCode')}
                  defaultValue={statisticByIdToEdit?.sqlCode || ''}
                  minRows={3}
                  style={{ width: '100%' }}
                  onChange={(event: any) => {
                    setCodeSql(event.target.value);
                  }}
                />
                {getFieldState('sqlCode').error && (
                  <FormHelperErrorText>
                    {getFieldState('sqlCode')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.graph')}
                </ParagraphTypography>
                <FormControl
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '100%',
                      lg: '100%',
                    },
                  }}
                >
                  <Select
                    {...register('graph')}
                    displayEmpty={true}
                    defaultValue={statisticByIdToEdit?.graph || ''}
                    fullWidth
                    error={getFieldState('graph').error ? true : false}
                  >
                    {GraphTypes?.map((item, key: number) => {
                      return (
                        <MenuItem
                          value={item}
                          key={key}
                          onClick={() => {
                            setGraphType(item);
                          }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {getFieldState('graph').error && (
                  <FormHelperErrorText>
                    {getFieldState('graph')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.idGlobalRoles')}
                </ParagraphTypography>
                <FormControl
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '100%',
                      lg: '100%',
                    },
                  }}
                >
                  <Select
                    {...register('idGlobalRoles')}
                    multiple
                    onChange={handleChangeRoleCheckbox}
                    value={idGlobalRolesCheckbox || []}
                    error={getFieldState('idGlobalRoles').error ? true : false}
                    type="array"
                    fullWidth
                    renderValue={(selected) =>
                      selected
                        ?.map((x: any) =>
                          handleLabelTranslation(x.globalRoleName)
                        )
                        .join(', ')
                    }
                  >
                    {globalRole?.map((role: any, key: number) => {
                      return (
                        <MenuItem value={role} key={key}>
                          <Checkbox
                            checked={
                              idGlobalRolesCheckbox &&
                              idGlobalRolesCheckbox?.findIndex(
                                (item: any) => item.id === role.id
                              ) >= 0
                            }
                          />
                          <ListItemText primary={role.displayName} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {getFieldState('idGlobalRoles').error && (
                  <FormHelperErrorText>
                    {getFieldState('idGlobalRoles')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.statisticApps')}
                </ParagraphTypography>
                <FormControl
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '80%',
                      lg: '100%',
                    },
                  }}
                >
                  <Select
                    {...register('statisticApps')}
                    multiple
                    onChange={handleChangeAppCheckbox}
                    error={getFieldState('statisticApps').error ? true : false}
                    value={appsCheckbox || []}
                    displayEmpty={true}
                    renderValue={(selected) =>
                      selected
                        ?.map((x: AppData) => x.applicationName)
                        .join(', ')
                    }
                    fullWidth
                  >
                    {apps?.map((app: any, key: number) => {
                      return (
                        <MenuItem value={app} key={key}>
                          <Checkbox
                            checked={
                              appsCheckbox &&
                              appsCheckbox?.findIndex(
                                (item: AppData) => item.id === app.id
                              ) >= 0
                            }
                          />
                          <ListItemText primary={app.applicationName} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {getFieldState('statisticApps').error && (
                  <FormHelperErrorText>
                    {getFieldState('statisticApps')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.priority')}
                </ParagraphTypography>
                <Select
                  {...register('priority')}
                  displayEmpty={true}
                  error={getFieldState('priority').error ? true : false}
                  defaultValue={statisticByIdToEdit?.priority || ''}
                  fullWidth
                >
                  {priorities?.map((item, key: number) => {
                    return (
                      <MenuItem value={item} key={key}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                {getFieldState('priority').error && (
                  <FormHelperErrorText>
                    {getFieldState('priority')?.error?.message}
                  </FormHelperErrorText>
                )}
              </Grid>
              <Grid container item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                <ParagraphTypography>
                  {t('statisticConfig:form.enabled')}
                </ParagraphTypography>
                <Checkbox
                  {...register('enabled')}
                  defaultChecked={statisticByIdToEdit?.enabled || false}
                />
              </Grid>
            </Grid>
            <Box>
              <SecondaryButton
                type="submit"
                typography={t('statisticConfig:form.submitButton')}
              />
            </Box>
          </form>
        </FormProvider>
      </CardContent>
    </Grid>
  );
};

export default StatisticForm;

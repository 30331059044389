import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeaderAppsType,
  useAuthStore,
  useMenuStore,
  useNavbarStore,
} from '@exertis/template';

import { globalRoles } from '../constants/globalRoles';
import { useGetAppsByRoleEnabled } from './access';

/**
 * Hook to initialize menu
 */
export const useInitMenu = () => {
  const { t } = useTranslation();
  const { me } = useAuthStore();
  const { setItems } = useMenuStore();

  /**
   * Init sidebar items
   */
  const dashboardSection = [
    {
      href: '/',
      title: t('sideBar:dashboard'),
    },
    {
      href: '/active',
      title: t('sideBar:activeStat'),
    },
    {
      href: '/inactive',
      title: t('sideBar:inactiveStat'),
    },
  ];

  const sidebarItems = [
    {
      title: 'Menu',
      pages: dashboardSection,
    },
  ];

  useEffect(() => {
    const init = () => {
      if (
        me?.globalRole.globalRoleName !== globalRoles[2] &&
        me?.role.roleName !== 'it'
      ) {
        const _sidebarItems = sidebarItems.map((sidebarItem: any) => {
          return {
            title: sidebarItem.title,
            pages: sidebarItem.pages.filter(
              (sidebarItemPage: any) => sidebarItemPage.href === '/'
            ),
          };
        });
        setItems(_sidebarItems);
      } else {
        setItems(sidebarItems);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, localStorage.getItem('language')]);
};

/**
 * Hook to initialize navbar apps
 */
export const useInitNavbarApps = () => {
  const { me } = useAuthStore();
  const { setApps } = useNavbarStore();

  const { data: apps } = useGetAppsByRoleEnabled(
    me?.globalRole.globalRoleName || '',
    me?.role.roleName || ''
  );
  const appToDisplay: any = [];
  useEffect(() => {
    const init = () => {
      if (me && apps) {
        apps?.data.map((app: HeaderAppsType) => {
          appToDisplay.push({
            image: `${import.meta.env.VITE_AP_API_BASE_URL}/apps/${
              app.appId
            }/image`,
            applicationName: app.app?.applicationName,
            hasLogo: app.app?.hasLogo,
            applicationUrl: app.app?.applicationUrl,
          });
          return null;
        });
        setApps(appToDisplay);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, apps]);
};

import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '@exertis/template';

import { Grid, Box } from '@mui/material';
import { styled } from '@mui/system';

/**
 * Styled components
 */
const Typography = styled('span')({
  fontSize: 15,
  fontWeight: 400,
  letterSpacing: 0.1,
  marginRight: 10,
  color: '#ffffff',
});

const StyledBox = styled(Box)({
  width: 'auto',
  height: 'auto',
  background: '#2c3e50',
  padding: 10,
  borderRadius: 12,
});

type Props = {
  codeSql?: string;
  refetch: any;
};

const StatisticFormPreview = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const { codeSql, refetch } = props;

  /**
   * Preview stat on click button
   */
  const handlePreviewStat = () => {
    refetch();
  };

  /**
   * Return TSX content
   */
  return (
    <Grid item xs={12} md={12} lg={12}>
      <StyledBox>
        <Typography>
          <pre style={{ whiteSpace: 'break-spaces' }}>{codeSql}</pre>
        </Typography>
      </StyledBox>
      <Grid>
        <SecondaryButton
          onClick={handlePreviewStat}
          typography={t('statisticConfig:form.testCode')}
          style={{
            width: 250,
            height: 36,
            marginTop: 60,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default StatisticFormPreview;

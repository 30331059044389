import { useEffect, useState } from 'react';
import {
  AppData,
  BlackTypography,
  FilterTypography,
  GlobalRoleType,
} from '@exertis/template';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';

const FormControl = styled(MuiFormControl)({
  margin: '8px 8px 8px 0px',
});

type Props = {
  isActive: boolean;
  globalRole: GlobalRoleType[] | undefined;
  apps: AppData[] | undefined;
  setGlobalRoleValues(globalRoleValues: GlobalRoleType[]): void;
  setAppValues(appValues: AppData[]): void;
};

const StatisticFilter = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const { isActive, globalRole, apps, setGlobalRoleValues, setAppValues } =
    props;

  /**
   * Init states
   */
  const [filterByRole, setFilterByRole] = useState<GlobalRoleType[]>([]);
  const [filterByApp, setFilterByApp] = useState<AppData[]>([]);

  /**
   * Handle globalRole checkbox changes
   * @param event
   */
  const handleChangeRoleCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: GlobalRoleType[] = [];

    value.forEach((item: any) => {
      if (
        duplicateRemoved.findIndex((o: GlobalRoleType) => o.id === item.id) >= 0
      ) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter(
            (x: GlobalRoleType) => x.id === item.id
          );
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setFilterByRole(duplicateRemoved);
  };

  /**
   * Handle apps checkbox changes
   * @param event
   */
  const handleChangeAppCheckbox = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: AppData[] = [];

    value.forEach((item: any) => {
      if (duplicateRemoved.findIndex((o: AppData) => o.id === item.id) >= 0) {
        if (duplicateRemoved.length === 1) {
          duplicateRemoved.pop();
        } else {
          duplicateRemoved = duplicateRemoved.filter(
            (x: AppData) => x.id === item.id
          );
        }
      } else {
        duplicateRemoved.push(item);
      }
    });
    setFilterByApp(duplicateRemoved);
  };

  /**
   * Handle change language
   */
  const handleLabelTranslation = (label: string) => {
    switch (label) {
      case 'clients':
        return t('common:globalRoles.clients');
      case 'collaborateurs':
        return t('common:globalRoles.collaborateurs');
      case 'fournisseurs':
        return t('common:globalRoles.fournisseurs');
      default:
        return '';
    }
  };

  /**
   * Initiate filters
   */
  useEffect(() => {
    setGlobalRoleValues([]);
    setFilterByRole([]);
    setAppValues([]);
    setFilterByApp([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  /**
   * Return TSX content
   */
  return (
    <>
      <Grid container sx={{ mb: { xs: 12, md: 12, lg: 12 } }}>
        <Grid item xs={10} md={12} lg={12}>
          <Grid container rowSpacing={5}>
            <Grid container item xs={12} md={4} lg={4} alignItems="center">
              <BlackTypography style={{ marginRight: 25 }}>
                {t('statistic:stat.globalRole')}
              </BlackTypography>
              <FormControl
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '80%',
                    lg: '300px',
                  },
                }}
              >
                <Select
                  name="globalRole"
                  multiple
                  value={filterByRole}
                  onChange={handleChangeRoleCheckbox}
                  style={{ height: 36 }}
                  renderValue={(selected) =>
                    selected
                      ?.map((x: any) =>
                        handleLabelTranslation(x.globalRoleName)
                      )
                      .join(', ')
                  }
                >
                  {globalRole?.map((role: any, key: number) => {
                    return (
                      <MenuItem key={role.id} value={role}>
                        <Checkbox
                          checked={
                            filterByRole &&
                            filterByRole.findIndex(
                              (item: any) => item.id === role.id
                            ) >= 0
                          }
                        />
                        <ListItemText primary={role.displayName} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={4} lg={4} alignItems="center">
              <BlackTypography style={{ marginRight: 25 }}>
                {t('statistic:stat.apps')}
              </BlackTypography>
              <FormControl
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '80%',
                    lg: '300px',
                  },
                }}
              >
                <Select
                  name="destination"
                  multiple
                  value={filterByApp}
                  onChange={handleChangeAppCheckbox}
                  style={{ height: 36 }}
                  renderValue={(selected) =>
                    selected?.map((x: AppData) => x.applicationName).join(', ')
                  }
                >
                  {apps?.map((app: any, key: number) => {
                    return (
                      <MenuItem key={app.id} value={app}>
                        <Checkbox
                          checked={
                            filterByApp &&
                            filterByApp.findIndex(
                              (item: AppData) => item.id === app.id
                            ) >= 0
                          }
                        />
                        <ListItemText primary={app.applicationName} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={3} lg={3} alignItems="center">
              <FilterTypography
                onClick={() => {
                  setGlobalRoleValues(filterByRole);
                  setAppValues(filterByApp);
                }}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '80%',
                    lg: '300px',
                  },
                }}
                style={{ paddingTop: 5 }}
              >
                {t('statistic:filter.applyFilter')}
              </FilterTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StatisticFilter;

import { useEffect, useState } from 'react';
import {
  CircularChartTypes,
  GraphTypes,
  PrimaryTypography,
  SeparatorComponent,
  StatItem,
} from '@exertis/template';
import { useTranslation } from 'react-i18next';

type Props = {
  previewStat?: any[];
  graphType: any;
};

const StatisticResultGraph = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const { previewStat, graphType } = props;

  /**
   * Init states
   */
  const [labelsValue, setLabelsValue] = useState<any[]>([]);
  const [seriesValue, setSeriesValue] = useState<any[]>([]);
  const [isStacked, setIsStacked] = useState(false);

  /**
   * Map stats from api to an understandable format by the component
   * @param previewStat
   * @returns
   */
  const handleSeries = (previewStat: any[], graphType: string) => {
    if (CircularChartTypes.includes(graphType)) {
      setSeriesValue(previewStat?.map((item: any) => Object.values(item)[1]));
    } else if (graphType === 'treemap') {
      const xaxis = Object.keys(previewStat[0])[0];
      setSeriesValue(
        Object.keys(previewStat[0])
          .slice(1)
          .map((itemTitle: any) => {
            return {
              name: itemTitle,
              data: previewStat.map((item: any) => ({
                x: item[xaxis],
                y: item[itemTitle],
              })),
            };
          })
      );
    } else {
      setSeriesValue(
        Object.keys(previewStat[0])
          .slice(1)
          .map((itemTitle: any) => {
            return {
              name: itemTitle,
              data: previewStat.map((item: any) => item[itemTitle]),
            };
          })
      );
    }
  };

  /**
   * Init x axis list and y axis list
   */
  useEffect(() => {
    if (previewStat && previewStat.length > 0) {
      setLabelsValue(previewStat?.map((item: any) => Object.values(item)[0]));
      handleSeries(previewStat, graphType);
    }
    setIsStacked(false);
    if (graphType === GraphTypes[3]) {
      setIsStacked(true);
    }
  }, [previewStat, graphType]);

  /**
   * Return TSX content
   */
  return (
    <>
      {previewStat && previewStat.length > 0 && (
        <>
          <SeparatorComponent />
          <PrimaryTypography style={{ color: '#4D4D4D', marginBottom: 30 }}>
            {t('statisticConfig:form.graphResult')}
          </PrimaryTypography>
          {previewStat && graphType && (
            <StatItem
              graphType={isStacked ? 'bar' : graphType}
              series={seriesValue}
              labels={labelsValue}
              stacked={isStacked}
            />
          )}
        </>
      )}
    </>
  );
};

export default StatisticResultGraph;

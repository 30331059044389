import { useTranslation } from 'react-i18next';
import {
  useQuery,
  UseMutationResult,
  useQueryClient,
  useQueries,
} from '@tanstack/react-query';
import {
  ApiError,
  AppData,
  GlobalRoleType,
  httpApi,
  Toast,
  useAppMutation,
} from '@exertis/template';

import { ApiStatisticResponse, Statistic } from '../types/StatisticTypes';

/**
 * Get statistic list hook
 */
export function useGetStatisticList(
  paginationPage: number,
  isActive: boolean,
  globalRoles: GlobalRoleType[],
  apps: AppData[]
) {
  const idGlobalRoles = globalRoles
    .map((globalRole: GlobalRoleType) => globalRole.id)
    .join(',');
  const idApps = apps.map((app: AppData) => app.id).join(',');

  let filter = '';
  if (globalRoles.length > 0)
    filter = filter.concat(
      `&filter[statisticGlobalRoles.idGlobalRole][in]=${idGlobalRoles}`
    );
  if (apps.length > 0)
    filter = filter.concat(`&filter[statisticApps.appId][in]=${idApps}`);

  return useQuery(
    ['stats', paginationPage, isActive, globalRoles, apps],
    async (): Promise<ApiStatisticResponse> =>
      await httpApi.get(
        `/statistics?page=${paginationPage}&filter[enabled][eq]=${
          isActive ? '1' : '0'
        }&expand=globalRoles,apps${filter}`
      )
  );
}

/**
 * Get statistic by id hook
 * @param statisticId
 * @returns
 */
export function useGetStatisticById(statisticId: string | undefined) {
  return useQuery(
    ['stats', statisticId],
    async (): Promise<Statistic> =>
      await httpApi.get(`/statistics/${statisticId}`),
    {
      enabled: !!statisticId,
    }
  );
}

/**
 * Add statistic hook
 * @returns
 */
export function AddStatistic(): UseMutationResult<Statistic, ApiError> {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useAppMutation(
    ['stats'],
    async (data: any) => await httpApi.post('/statistics', data),
    {
      onPostSuccess: () => {
        queryClient.removeQueries({ queryKey: ['stats'] });
        queryClient.invalidateQueries({ queryKey: ['stat-dashboard'] });
        Toast({
          title: t('statistic:stat.apiResponse.statAdded'),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * Edit statistic hook
 * @returns
 */
export function EditStatistic(): UseMutationResult<Statistic, ApiError> {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useAppMutation(
    ['stats'],
    async ({ id, data }: { id: string; data: Statistic }) =>
      await httpApi.put(`/statistics/${id}`, data),
    {
      onPostSuccess: () => {
        queryClient.removeQueries({ queryKey: ['stats'] });
        queryClient.invalidateQueries({ queryKey: ['stat-dashboard'] });
        Toast({
          title: t('statistic:stat.apiResponse.statChanged'),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * Delete statistic hook
 * @returns
 */
export function DeleteStatistic(): UseMutationResult<Statistic, ApiError> {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useAppMutation(
    ['stats'],
    async (id: string) => await httpApi.delete(`/statistics/${id}`),
    {
      onPostSuccess: () => {
        queryClient.removeQueries({ queryKey: ['stats'] });
        queryClient.invalidateQueries({ queryKey: ['stat-dashboard'] });
        Toast({
          title: t('statistic:stat.apiResponse.statDeleted'),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * Get statistic preview
 * @param databaseName
 * @param sqlCode
 * @param graph
 * @returns
 */
export function usePreviewStatistic(
  databaseName: string,
  sqlCode: string,
  graph: string
) {
  const { t } = useTranslation();
  return useQuery(
    ['preview-stats', databaseName, sqlCode, graph],
    async (): Promise<any> =>
      await httpApi.post('/statistics/preview', {
        databaseName,
        sqlCode,
        graph,
      }),
    {
      enabled: false,
      onSuccess(data) {
        if (data.length === 0) {
          Toast({
            title: t('statistic:stat.apiResponse.emptyResponse'),
            method: 'error',
          });
        }
      },
    }
  );
}

/**
 * Get statistic sorted hook
 * @returns
 */
export function useGetStatisticSorted(idApp: string | undefined) {
  return useQuery(
    ['stat-dashboard', idApp],
    async (): Promise<any> => await httpApi.get(`/dashboard?idApp=${idApp}`)
  );
}

/**
 * Get statistics queries
 * @param statisticIds
 * @returns
 */
export function useGetStatisticQueries(statisticIds: string[]) {
  return useQueries({
    queries: statisticIds.map((item) => ({
      queryKey: ['stat-query', item],
      queryFn: async (): Promise<any> =>
        await httpApi.get(`/statistics/${item}/data`),
    })),
  });
}

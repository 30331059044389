import { useState } from 'react';
import {
  AppData,
  BackdropLoader,
  BreadCrumbs,
  GlobalRoleType,
  Header,
  SeparatorComponent,
  useAuthStore,
} from '@exertis/template';
import { useTranslation } from 'react-i18next';

import StatisticFilter from '../components/statistic/StatisticFilter';
import StatisticList from '../components/statistic/StatisticList';
import { useGetApps, useGlobalRoleList } from '../hooks/access';
import { DeleteStatistic, useGetStatisticList } from '../hooks/statistic';

type Props = {
  isActive: boolean;
};

const Statistic = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();
  const { me } = useAuthStore();

  /**
   * Init props
   */
  const { isActive } = props;

  /**
   * Global states
   */
  const [paginationPage, setPaginationPage] = useState(1);
  const [globalRoleValues, setGlobalRoleValues] = useState<GlobalRoleType[]>(
    []
  );
  const [appValues, setAppValues] = useState<AppData[]>([]);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  /**
   * GlobalRole api calls
   */
  const { data: globalRole, isInitialLoading: isGlobalRoleLoading } =
    useGlobalRoleList();

  /**
   * Apps api calls
   */
  const { data: apps, isInitialLoading: isAppLoading } = useGetApps();

  /**
   * Statistic api calls
   */
  const { data, isInitialLoading: isStatisticLoading } = useGetStatisticList(
    paginationPage,
    isActive,
    globalRoleValues,
    appValues
  );
  const { mutate: mutateDeleteStatistic } = DeleteStatistic();

  /**
   * Init breadCrumb items
   */
  const routeItems = [
    {
      path: '/active',
      links: [
        { title: t('statistic:header.breadCrumbs_first_child'), href: '/' },
        {
          title: t('statistic:header.breadCrumbs_second_child_active'),
          href: '/active',
        },
      ],
    },
    {
      path: '/inactive',
      links: [
        { title: t('statistic:header.breadCrumbs_first_child'), href: '/' },
        {
          title: t('statistic:header.breadCrumbs_second_child_inactive'),
          href: '/inactive',
        },
      ],
    },
  ];

  /**
   * Return TSX content
   */
  return (
    <>
      <Header
        welcome={t('statistic:header.welcome')}
        username={me?.firstName || ''}
        titlePage={
          isActive
            ? t('statistic:header.titleActive')
            : t('statistic:header.titleInActive')
        }
        breadCrumbs={BreadCrumbs(routeItems)}
      />
      <SeparatorComponent />
      {
        <BackdropLoader
          open={isStatisticLoading || isGlobalRoleLoading || isAppLoading}
        />
      }
      <StatisticFilter
        isActive={isActive}
        globalRole={globalRole?.data}
        apps={apps?.data}
        setGlobalRoleValues={setGlobalRoleValues}
        setAppValues={setAppValues}
      />
      <StatisticList
        data={data}
        paginationPage={paginationPage}
        isSuccessModalVisible={isSuccessModalVisible}
        mutateDeleteStatistic={mutateDeleteStatistic}
        setPaginationPage={setPaginationPage}
        setIsSuccessModalVisible={setIsSuccessModalVisible}
      />
    </>
  );
};

export default Statistic;

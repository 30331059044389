import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmationModal,
  moreTableIcon,
  PrimaryButton,
  SuccessModal,
} from '@exertis/template';

import { styled, spacing } from '@mui/system';
import {
  Grid,
  Typography as MuiTypography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Pagination as MuiPagination,
  IconButton,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';

import {
  ApiStatisticResponse,
  Statistic,
  StatisticApp,
  StatisticGlobalRole,
} from '../../types/StatisticTypes';
import MenuItemsComponent from './MenuItemsComponent';

/**
 * Styled components
 */
const ColumnHeaderTypography = styled(MuiTypography)({
  fontWeight: '550',
  color: '#9E9E9E',
  fontSize: 14,
  lineHeight: 5,
});

const Typography = styled(MuiTypography)(spacing);

const Pagination = styled(MuiPagination)(spacing);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRight: '0.5px solid #E0E0E0',
    borderBottom: '0',
    padding: '0px 0px 0px 10px',
    height: '30px',
    '&:nth-of-type(1)': {
      borderRight: 0,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: '0.5px solid #E0E0E0',
    borderBottom: '0',
    padding: '10px',
    '&:nth-of-type(1)': {
      padding: '0px 0px 0px 0px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const IconMoreAction = styled('img')({
  width: 15,
  height: 15,
});

type Props = {
  data: ApiStatisticResponse | undefined;
  paginationPage: number;
  isSuccessModalVisible: boolean;
  mutateDeleteStatistic: any;
  setPaginationPage(page: number): void;
  setIsSuccessModalVisible(isSuccessModalVisible: boolean): void;
};

const StatisticList = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init navigation
   */
  let navigate = useNavigate();

  /**
   * Init props
   */
  const {
    data,
    paginationPage,
    isSuccessModalVisible,
    mutateDeleteStatistic,
    setPaginationPage,
    setIsSuccessModalVisible,
  } = props;

  /**
   * Init states
   */
  const [anchorEl, setAnchorEl] = useState(null);
  const [tags, setTags] = useState('');
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [statisticToEdit, setStatisticToEdit] = useState<Statistic | null>(
    null
  );

  /**
   * Open action menu for specific row
   */
  const handleOpenMenu = (event: any, list: any) => {
    setAnchorEl(event?.currentTarget);
    setStatisticToEdit(list);
  };

  /**
   * Action to do after doing action confirmation
   * @param confirmation
   */
  const handleModalConfirmation = async (confirmation: boolean) => {
    if (confirmation && tags === 'deleteStat') {
      mutateDeleteStatistic(statisticToEdit?.id);
      setStatisticToEdit(null);
    }
  };

  /**
   * Close action menu for specific row
   */
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setStatisticToEdit(null);
  };

  /**
   * Show delete confirmation modal
   */
  const showConfirmationModal = () => {
    setIsConfirmationModalVisible(true);
    setAnchorEl(null);
  };

  /**
   * Hide modal after delete
   */
  const hideModal = () => {
    setTags('');
    setIsConfirmationModalVisible(false);
    setIsSuccessModalVisible(false);
  };

  /**
   * Handle change page pagination
   */
  const handleChangePage = (_: any, value: any) => {
    setPaginationPage(value);
  };

  /**
   * Handle change language
   */
  const handleLabelTranslation = (label: string) => {
    switch (label) {
      case 'clients':
        return t('common:globalRoles.clients');
      case 'collaborateurs':
        return t('common:globalRoles.collaborateurs');
      case 'fournisseurs':
        return t('common:globalRoles.fournisseurs');
      default:
        return '';
    }
  };

  /**
   * Return TSX content
   */
  return (
    <>
      <ConfirmationModal
        openModal={isConfirmationModalVisible}
        title={
          tags === 'deleteStat'
            ? t('statistic:modals.confirmationDelete.title')
            : null
        }
        description={
          tags === 'deleteStat'
            ? t('statistic:modals.confirmationDelete.description')
            : null
        }
        titleButton={
          tags === 'deleteStat'
            ? t('statistic:modals.confirmationDelete.confirmationButton')
            : null
        }
        cancelButton={t('common:cancel')}
        handleModalConfirmation={handleModalConfirmation}
        hideDialog={hideModal}
      />
      <SuccessModal
        openModal={isSuccessModalVisible}
        title={
          tags === 'deleteStat'
            ? t('statistic:modals.successDelete.title')
            : null
        }
        description={
          tags === 'deleteStat'
            ? t('statistic:modals.successDelete.description')
            : null
        }
        cancelButton={t('common:cancel')}
        hideSuccessModal={hideModal}
      />
      {data && (
        <Grid container item xs={12} justifyContent="center">
          {data.data.length !== 0 ? (
            <>
              <TableContainer component={Paper} style={{ width: '100%' }}>
                <Table
                  aria-label="simple table"
                  style={{ overflowY: 'hidden' }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t('statistic:stat.name')}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t('statistic:stat.description')}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t('statistic:stat.globalRole')}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ColumnHeaderTypography>
                          {t('statistic:stat.apps')}
                        </ColumnHeaderTypography>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data.map((item: Statistic) => (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell align="center">
                          <IconButton
                            onClick={(e: any) => handleOpenMenu(e, item)}
                          >
                            <IconMoreAction src={moreTableIcon()} />
                          </IconButton>
                          <MenuItemsComponent
                            statistic={statisticToEdit}
                            anchorEl={anchorEl}
                            handleCloseMenu={handleCloseMenu}
                            setTags={setTags}
                            showConfirmationModal={showConfirmationModal}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>{item.name}</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>{item.description}</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>
                            {item.statisticGlobalRoles
                              .map((globalRole: StatisticGlobalRole) =>
                                handleLabelTranslation(
                                  globalRole.globalRoleName
                                )
                              )
                              .join(', ')}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>
                            {item.statisticApps
                              .map((app: StatisticApp) => app.appName)
                              .join(', ')}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                container
                justifyContent="flex-end"
                style={{ width: '100%' }}
              >
                <Pagination
                  mt={5}
                  mb={5}
                  count={Math.ceil(Number(data?.meta.total) / 20)}
                  page={paginationPage}
                  onChange={handleChangePage}
                  color="primary"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent={'center'} sx={{ mt: 15 }}>
                <Typography variant="h6" gutterBottom display="inline">
                  {t('statistic:stat.emptyTable')}
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            container
            justifyContent="center"
            style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
          >
            <PrimaryButton
              type="submit"
              onClick={() => navigate('/statistic-config')}
              startIcon={<AddIcon color="inherit" />}
              typography={t('statistic:stat.addStat')}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StatisticList;

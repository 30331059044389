import { ThemeProvider } from '@emotion/react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Authorization,
  ErrorFallback,
  queryClient,
  theme,
  setBaseUrl,
} from '@exertis/template';
import './i18n';
import config from './config';
import { useInitMenu } from './hooks/app';
import routes from './routes';
import { useInitConfig } from './hooks/config';

export default function App() {
  useInitMenu();
  useInitConfig();

  const content = useRoutes(routes);
  setBaseUrl(config.statisticApiBaseUrl);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Authorization>{content}</Authorization>
        </ErrorBoundary>
        <ToastContainer />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryTypography } from '@exertis/template';

import {
  Typography as MuiTypography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/system';

/**
 * Styled components
 */
const ColumnHeaderTypography = styled(MuiTypography)({
  fontWeight: '550',
  color: '#4D4D4D',
  textAlign: 'center',
  fontSize: 12,
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRight: '0.5px solid #E0E0E0',
    borderBottom: '0',
    padding: '0px 0px 0px 10px',
    height: '30px',
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: '0.5px solid #E0E0E0',
    borderBottom: '0',
    padding: '10px',
    '&:nth-of-type(1)': {
      padding: '0px 0px 0px 0px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

type Props = {
  previewStat?: any[];
};

const StatisticResultList = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const { previewStat } = props;

  /**
   * Init table columns
   */
  const columns: string[] = [];

  /**
   * Define columns based on api response
   */
  const handleColumns = () => {
    if (previewStat && previewStat.length > 0) {
      Object.keys(previewStat[0]).forEach((item) => columns.push(item));
    }
  };

  useEffect(() => {
    if (previewStat) {
      handleColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewStat, handleColumns()]);

  /**
   * Return TSX content
   */
  return (
    <>
      {previewStat && previewStat.length > 0 && (
        <>
          <PrimaryTypography style={{ color: '#4D4D4D', marginBottom: 30 }}>
            {t('statisticConfig:form.dataResult')}
          </PrimaryTypography>
          <TableContainer component={Paper} style={{ height: '300px' }}>
            <Table aria-label="simple table" style={{ overflowY: 'hidden' }}>
              <TableHead>
                <TableRow>
                  {columns.map((item, index) => (
                    <StyledTableCell align="center" key={index}>
                      <ColumnHeaderTypography>{item}</ColumnHeaderTypography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {previewStat?.map((row: any, index: number) => (
                  <StyledTableRow key={index}>
                    {columns.map((item, index) => (
                      <StyledTableCell align="center" key={index}>
                        {row[Object.keys(row)[index]]}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default StatisticResultList;

import { useQuery } from '@tanstack/react-query';
import {
  ApiGlobalRoleResponse,
  ApiType,
  AppData,
  HeaderAppByRole,
  httpApi,
  ListAccessessType,
} from '@exertis/template';

interface GetListAccess {
  data: ListAccessessType[];
  meta: ApiType;
}
interface ApiAppDataResponse {
  data: AppData[];
  meta: ApiType;
}
/**
 * Get global role list
 */
export function useGlobalRoleList() {
  return useQuery(
    ['globalRole'],
    async (): Promise<ApiGlobalRoleResponse> =>
      await httpApi.get(
        `${import.meta.env.VITE_AP_API_BASE_URL}/globalroleparams`
      )
  );
}

/**
 * hook to call api to get list apps by role enabled
 * @param globalRoleName
 * @param roleName
 * @returns
 */
export function useGetAppsByRoleEnabled(
  globalRoleName: string,
  roleName: string
) {
  return useQuery(
    ['appsByRoleEnabled', globalRoleName, roleName],
    async (): Promise<HeaderAppByRole> =>
      await httpApi.get(
        `${
          import.meta.env.VITE_AP_API_BASE_URL
        }/appbyroles?${encodeURIComponent(
          'filter[globalRole.globalRoleName][eq]'
        )}=${globalRoleName}&${encodeURIComponent(
          'filter[role.roleName][eq]'
        )}=${roleName}&${encodeURIComponent(
          'filter[app.status][eq]'
        )}=enabled&expand=globalRole,role,app`
      )
  );
}

/**
 * Hook to get user information
 */
export function useGetUserByEmail(email: string) {
  return useQuery(
    ['accessLists', email],
    async (): Promise<GetListAccess> =>
      await httpApi.get(
        `${import.meta.env.VITE_AP_API_BASE_URL}/accesses?${encodeURIComponent(
          'filter[email][eq]'
        )}=${email}&expand=globalRole,role&limit=1`
      )
  );
}

/**
 * Hook to get applications list
 */
export function useGetApps() {
  return useQuery(
    ['apps'],
    async (): Promise<ApiAppDataResponse> =>
      await httpApi.get(`${import.meta.env.VITE_AP_API_BASE_URL}/apps`)
  );
}

import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Statistic } from '../../types/StatisticTypes';

type Props = {
  statistic: Statistic | null;
  anchorEl: any;
  handleCloseMenu(): void;
  setTags(tags: string): void;
  showConfirmationModal(): void;
};

const MenuItemsComponent = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init navigation
   */
  let navigate = useNavigate();

  /**
   * Init props
   */
  const {
    statistic,
    anchorEl,
    handleCloseMenu,
    setTags,
    showConfirmationModal,
  } = props;

  /**
   * Return TSX content
   */
  return (
    <>
      <Menu
        sx={{
          borderRadius: 4,
          width: 220,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => navigate(`/statistic-config/${statistic?.id}`)}
        >
          {t('statistic:stat.updateStat')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTags('deleteStat');
            showConfirmationModal();
          }}
        >
          {t('statistic:stat.deleteStat')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuItemsComponent;
